import React, { useState } from "react";
import { Col, Row } from "reactstrap";

const QuickLinks = (props) => {
  const [show, setShow] = useState(true);

  var custom_style = {
    display: "inline-block",
    color: "white",
    textAlign: "center",
    padding: "0px",
    textDecoration: "none",
  };

  var auctions_office_phone = null;
  // if (props.site == 'er'){
  //     auctions_office_phone = <Row id={'auctionOfficeContact'}
  //                                  className={'my-auto'}
  //                             >
  //                                 <Col xs={12} className={'bg-white text-center'}>
  //                                     <br />
  //                                     <p><b>Oficina de subastas: </b>0343 4206140 (directo) - 0343 4206100 (interno 248)</p>
  //                                 </Col>
  //                             </Row>
  // }

  var row = (
    <div
      className={"text-center"}
      style={{
        overflow: "auto",
        whiteSpace: "nowrap",
      }}
    >
      <div className={"p-1"} style={custom_style} hidden={props.site !== 'privadas'}>
        <a
          className={`btn btn-${props.sources[props.site].theme_name} btn-sm`}
          href={"https://mkt.esubasta.ar/quienes-somos/"}
          target="_blank"
        >
          <b>Quienes Somos</b>
        </a>
      </div>
      <div className={"p-1"} style={custom_style} hidden={props.site !== 'privadas'}>
        <a
          className={`btn btn-${props.sources[props.site].theme_name} btn-sm`}
          href={"https://mkt.esubasta.ar/quiero-subastar/"}
        >
          <b>¡Quiero Subastar!</b>
        </a>
      </div>
      <div className={"p-1"} style={custom_style}>
        <a
          className={`btn btn-${props.sources[props.site].theme_name} btn-sm`}
          href={"/reglamento/"}
        >
          <b>Reglamento</b>
        </a>
      </div>
      <div className={"p-1"} style={custom_style}>
        <a
          className={`btn btn-${props.sources[props.site].theme_name} btn-sm`}
          href={"/guia/"}
        >
          <b>Tutoriales</b>
        </a>
      </div>
      <div className={"p-1"} style={custom_style}>
        <a
          className={`btn btn-${props.sources[props.site].theme_name} btn-sm`}
          href={"/comopagar/"}
        >
          <b>Cómo Pagar</b>
        </a>
      </div>
      <div className={"p-1"} style={custom_style}>
        <a
          className={`btn btn-${props.sources[props.site].theme_name} btn-sm`}
          href={"/contacto/"}
        >
          <b>Contacto</b>
        </a>
      </div>
      <div className={"p-1"} style={custom_style} hidden={props.site === "er" || props.site === "lp"}>
        <a
          className={`btn btn-${props.sources[props.site].theme_name} btn-sm`}
          href={"/preguntas_frecuentes/"}
        >
          <b>Preguntas frecuentes</b>
        </a>
      </div>
    </div>
  );
  return (
    <>
      {auctions_office_phone}
      <Row id={"links"}>
        <Col xs={12} className={"bg-white my-1 text-center"}>
          {/*<a className={'btn btn-sm btn-success pull-right text-white by-auto'}*/}
          {/*   onClick={this.hideLinks}*/}
          {/*>*/}
          {/*    <i className={'fa fa-caret-down'}/>*/}
          {/*</a>*/}
          {/*<div className={'btn btn-link btn-sm'} onClick={this.hideLinks}>Datos útiles</div>*/}
          <div hidden={!show}>{row}</div>
          {/*<a href={'#'} className={'btn btn-link btn-sm'} onClick={this.hideLinks}>{show_hide} Datos*/}
          {/*    útiles {caret}</a>*/}
        </Col>
      </Row>
    </>
  );
};

export default QuickLinks;
