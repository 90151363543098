import React from "react";
import Base from "components/Base";
import QuickLinks from "components/Navbars/QuickLinks";
import loadable from "@loadable/component";
// import CbaTerms from "./CbaTerms";
// import SETerms from "./SETerms";
// import SlTerms from "./SlTerms";
// import ErTerms from "./ErTerms";
// import LpTerms from "./LpTerms";

const CbaTerms = loadable(() => import("./CbaTerms"));
const SETerms = loadable(() => import("./SETerms"));
const SlTerms = loadable(() => import("./SlTerms"));
const ErTerms = loadable(() => import("./ErTerms"));
const LpTerms = loadable(() => import("./LpTerms"));
const JujuyTerms = loadable(() => import("./JujuyTerms"));
const ChacoTerms = loadable(() => import("./ChacoTerms"));

class TermsAndConditions extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {
        // ReactGA.pageview(window.location.pathname);
    }

    componentWillUnmount() {
    }

    renderContent() {
        return (
            <div className="row my-1">
                <div className="col-xs-12 text-center bg-white px-2">
                    {this.props.site === 'cba' ?
                        <CbaTerms />
                    : this.props.site === 'sl' ?
                        <SlTerms />
                    : this.props.site === 'privadas' ?
                        <SETerms />
                    : this.props.site === 'er' ?
                        <ErTerms />
                    : this.props.site === 'lp' ?
                        <LpTerms />
                    : this.props.site === 'jujuy' ?
                        <JujuyTerms />
                    : this.props.site === 'chaco' ?
                        <ChacoTerms />
                    :
                        <SETerms />
                    }
                </div>
            </div>
        );
    }

    render() {
        var children = <>
            <QuickLinks {...this.props}/>
            {this.renderContent()}
        </>;
        return (
            <Base children={children}
                site_name={"Subastas Electrónicas - Reglamento"}
                site_desc={"Página de reglamento de Subastas Electrónicas."}
                link_img={this.props.sources[this.props.site].defaultImg}
                {...this.props}
            />
        );
    }
}

export default TermsAndConditions;