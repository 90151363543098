import React from "react";

import ERLogo from "assets/img/brand/er/logo_er.png";
import ERLogoLight from "assets/img/brand/er/logo_er_inverted.png";
import ERFav from "assets/img/brand/er/er_iso.png";
import DefaultER from "assets/img/brand/er/defaultEntre Rios.png";

import InterBannerWA from "assets/img/brand/subastas_electronicas/inter_banners/Inter_bannerWA.webp";


export const site = 'er';

export const sources = {
    er: {
        url: document.location.origin,
        logo: ERLogo,
        logolight: ERLogoLight,
        ws_path: `${document.location.origin}/wss`.replace('http', 'ws').replace('https', 'wss'),
        name: 'Subastas Judiciales Entre Ríos',
        navbar_class: 'navbar-er',
        headroom_class: 'headroom-er',
        favicon: ERFav,
        theme_color: '#676978',
        theme_name: 'entrerios',
        defaultImg: DefaultER,
        interBanner: [{img: InterBannerWA, link: 'https://chat.whatsapp.com/FW2ngM0l3nNBHdL69N2eAo'}],

        title: 'Subastas Judiciales',
        recaptcha_key: '6LfYzrIcAAAAAFH2jhCkFSMF6fC_t2corBr52Awf',
        gtm_ID: 'GTM-MCJT3WD',
        contact_data: {
            title: 'Oficina de subastas PJ de Entre Ríos',
            lines: [
                    <p><b>Laprida 255 - 2do piso</b></p>,
                    <p><b>Palacio de tribunales de Paraná</b></p>,
                    <p><b>Teléfonos:</b></p>,
                    <p>&emsp; 0343 4206140 (directo)</p>,
                    <p>&emsp; 0343 4206100 (interno 248)</p>,
                    <p><b>email:</b> subastaselectronicas@jusentrerios.gov.ar</p>,
            ]
        },
        support_email: 'soporte.er@esubasta.ar'
    },
};